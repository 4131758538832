import React from 'react';
import useHeaderStyles from './styles';
import { Box, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import gmiLogo from '../../assets/gmist.gif';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
type Links = {
  url: string;
  title: string;
};
interface HeaderProps {
  links: Links[];
  toggleDialog: () => void;
  dialogOpen: boolean;
}

const Header = ({ links, dialogOpen, toggleDialog }: HeaderProps) => {
  const { classes } = useHeaderStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <header className={classes.mainBody}>
        <Box>
          <Link to={'/'}>
            <img src={gmiLogo} alt="Gourmet" />
          </Link>
        </Box>
        {!isMobile ? (
          <nav className="">
            <ul className={classes.linkWrapper}>
              {links.map((link, idx) => (
                <li key={`link-wrap_${idx}`}>
                  <NavLink
                    className={classes.link}
                    to={link.url}
                    key={`link_${idx}`}
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        ) : (
          <MenuIcon className={classes.hamburger} onClick={toggleDialog} />
        )}
      </header>

      <Dialog className={classes.dialog} open={dialogOpen} fullScreen>
        <Box onClick={toggleDialog}>
          <CancelIcon className={classes.closeIcon} />
        </Box>
        <Box className={classes.dialogMobileSpacer}>
          <ul className={classes.linkWrapper}>
            {links.map((link, idx) => (
              <li key={`link-wrap_${idx}`}>
                <NavLink
                  className={classes.link}
                  to={link.url}
                  key={`link_${idx}`}
                  onClick={toggleDialog}
                >
                  {link.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </Box>
      </Dialog>
    </>
  );
};

export default Header;
