import { Links } from '../components/Header';

const headerData: Links[] = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Our Customers',
    url: '/customers',
  },
  {
    title: 'Vendors Welcome',
    url: '/vendors',
  },
  {
    title: 'About GMI',
    url: '/about',
  },
  // {
  //   title: 'Careers',
  //   url: '/careers',
  // },
  {
    title: 'Contact',
    url: '/contact',
  },
];

export const mobileHeaderData: Links[] = [
  {
    title: 'Home',
    url: '/',
  },
  // {
  //   title: 'Specialty Products',
  //   url: '/products',
  // },
  {
    title: 'Our Customers',
    url: '/customers',
  },
  {
    title: 'Vendors Welcome',
    url: '/vendors',
  },
  {
    title: 'About GMI',
    url: '/about',
  },
  // {
  //   title: 'Careers',
  //   url: '/careers',
  // },
  {
    title: 'Contact',
    url: '/contact',
  },
];

export default headerData;
