import withHooks, { mapHooksToProps } from '../../common/utilities/withHooks';
import OurCustomersTemplate from './OurCustomersTemplate';

import wholeFoods from '../../assets/whole-foods.png';
import gelsons from '../../assets/gelsons.png';
import bristolFarms from '../../assets/bristol-farms.png';
import erewhon from '../../assets/erewhon.png';
import lazyAcres from '../../assets/lazy_acres_market.png';
import badia from '../../assets/partner_badia.png';
import stonewall from '../../assets/stonewall.png';

export type DistributorLinks = {
  image: string;
  url: string;
};
export interface OurCustomersTemplateData {
  title: string;
  distributors: {
    title: string;
    links: DistributorLinks[];
  };
  textContent: {
    main: string[];
    title: string;
    images?: string[];
  };
}
const hooks = mapHooksToProps(() => {
  const OurCustomersTemplateData: OurCustomersTemplateData = {
    title: 'Our Customers',
    distributors: {
      links: [
        {
          image: '',
          url: '',
        },
      ],
      title: 'Distributors',
    },
    textContent: {
      main: [
        `GMI is the consummate niche distributor serving the upscale gourmet and natural foods market in Southern California. As the only single source distributor serving the 5 key specialty retailers in this region, we have worked hard to become their go-to source for specialty and artisan products. Additionally, we serve a diverse group of independent operations from Santa Barbara to San Diego.`,
        `Equally important are the chains we don't distribute to: no mass-market supermarkets, drug or discount stores. We are proud to offer a uniquely dynamic assortment of excellent products to a targeted marketplace that appreciates the value of quality and the passion that goes into our brands.`,
        '',
        '',
        '',
        '',
        '',
      ],
      title: 'Our Customers',
      images: [
        wholeFoods,
        gelsons,
        bristolFarms,
        erewhon,
        lazyAcres,
        badia,
        stonewall,
      ],
    },
  };

  return OurCustomersTemplateData;
});

const OurCustomers = withHooks(OurCustomersTemplate, hooks);

export default OurCustomers;
