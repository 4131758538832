import React from 'react';

import useAboutPageStyles from './aboutStyles';
import { Box, Typography } from '@mui/material';
import PartnerList from '../../components/PartnerList';
import { AboutPageData } from '.';
import Tout from '../../components/Tout';

const AboutPage: React.FC<AboutPageData> = ({
  distributors,
  textContent,
  title,
}) => {
  const { classes } = useAboutPageStyles();

  return (
    <main>
      <section className={classes.mainWrapper}>
        <Box className={classes.sideNav}>
          <nav>
            <PartnerList />
          </nav>
        </Box>
        <Box className={classes.mainContentWrapper}>
          <Typography variant="h4">{textContent.title}</Typography>
          <Box className={classes.mainContent}>
            <article>
              {textContent.main.map((content, idx) => (
                <Box mb={4} key={`tout_${idx}`}>
                  <Tout
                    bodyText={content}
                    bodyTextVariant="body1"
                    bodyTextColor="MenuText"
                    imageUrl={textContent.images?.[idx]}
                    alignToutContent="normal"
                  />
                </Box>
              ))}
            </article>
            {/* <img className={classes.mainImage} src={iceCream} alt="ice-cream" /> */}
            {/* <a href="https://www.vecteezy.com/free-photos/dessert">
              Dessert Stock photos by Vecteezy
            </a> */}
          </Box>
        </Box>
      </section>
    </main>
  );
};

export default AboutPage;
